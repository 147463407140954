import React from "react";
import { Link } from "react-router-dom"; // Import Link for routing

import chatlogo2 from "../../assets/mainlogo.png";

function Navbar() {
  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200">
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
        <Link to={"/"} className="flex items-center space-x-3">
          <img src={chatlogo2} className="h-16" alt="chatlogo2" />
        </Link>
        <div className="flex space-x-3 md:order-2 md:space-x-0">
          <button
            type="button"
            className="px-4 py-2 text-sm font-bold text-center text-black bg-[#FED958] rounded-lg hover:bg-[#FED958] focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <a href="https://codecanyon.net/item/whoxa-chat-chat-script-web-whatsapp-clone-nodejs-chat-software-chat-website-group-chat/55359732"
            target="_blank" rel="noreferrer">
              Buy Now
            </a>
          </button>
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg md:p-0 bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link
                to="Features" // Use Link instead of a tag
                className="block px-3 py-2 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                aria-current="page"
              >
                Features
              </Link>
            </li>
            <li>
              <Link
                to="Settings" // Use Link instead of a tag
                className="block px-3 py-2 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
              >
                Settings
              </Link>
            </li>
            <li>
              <Link
                to="Technology" // Use Link instead of a tag
                className="block px-3 py-2 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
              >
                Technology
              </Link>
            </li>
            <li>
              <Link
                to="GroupChat" // Use Link instead of a tag
                className="block px-3 py-2 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
              >
                Group Chat
              </Link>
            </li>
            <li>
              <Link
                to="AdminPanel" // Use Link instead of a tag
                className="block px-3 py-2 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
              >
                Admin Panel
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
