import React from "react";
import bgimage from "../../assets/bgimage-section4.png";
import image1 from "../../assets/section4-1.png";
import socketLogo from "../../assets/socketsection-4.png";
import nodeLogo from "../../assets/node-js 1.png";
import mysqlLogo from "../../assets/mysqlsection4.png";
import react from "../../assets/rect.png";
import sms from "../../assets/sms.png";
import talwindcss1 from "../../assets/talwindcss.png";
import sms1 from "../../assets/111111.png";

function Homesection4() {
  return (
    <div
      className="px-4 my-8 text-center bg-white mt-[4rem] py-10"
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "10px",
        marginBottom: "50px",
      }}
    >
      {/* Heading and Line Section */}
      <div className="relative inline-block w-auto py-5 mx-auto">
        <h2 className="text-[24px] md:text-[32px] lg:text-[40px] font-bold flex justify-center gap-4 md:gap-3 flex-wrap">
          <span className="text-black latofont">Popular Technology</span>
        </h2>

        {/* Line with dots under heading */}
        <div className="flex items-center justify-center mt-2">
          <div className="w-3 h-3 bg-white border-2 border-black rounded-full"></div>
          <div className="flex-grow h-1 bg-black "></div>
          <div className="w-3 h-3 bg-white border-2 border-black rounded-full"></div>
        </div>
      </div>

      {/* Image and Content Section */}
      <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start mt-8 md:w-[70%] mx-auto  w-[95%]">
        {/* Left side image for large screens */}
        <div className="flex justify-center w-full mb-6 lg:w-1/2 lg:mb-0">
          <img
            src={image1}
            alt="whoxa chat script"
            className="w-full h-auto rounded-lg "
          />
        </div>

        <div className="flex flex-col w-full h-full px-3 space-y-10 lg:w-1/2 ">
          {/* Backend Technology */}
          <div className="grid items-center w-full mx-auto md:grid md:grid-rows-1 md:grid-cols-2 xl:mb-4">
            <h2 className="flex w-full mb-4 text-2xl font-bold md:text-2xl latofont">
              Backend Technology:
            </h2>
            <div className="flex space-x-3">
              {/* Socket.IO */}
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 bg-white rounded-full shadow-lg">
                  <img
                    src={socketLogo}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  />
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  Socket.IO
                </span>
              </div>

              {/* Node.js */}
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 bg-white rounded-full shadow-lg">
                  <img
                    src={nodeLogo}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  />
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  Node.js
                </span>
              </div>

              {/* MySQL */}
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 bg-white rounded-full shadow-lg">
                  <img
                    src={mysqlLogo}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  />
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  MySQL
                </span>
              </div>
            </div>
          </div>

          {/* Frontend Technology */}
          <div className="grid items-center w-full mx-auto md:grid md:grid-rows-1 md:grid-cols-2 xl:mb-4">
            <h2 className="flex w-full mb-4 text-2xl font-bold md:text-2xl latofont ">
              Frontend Technology:
            </h2>
            <div className="flex items-start w-full space-x-3 ">
              {/* Socket.IO */}
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 bg-white rounded-full shadow-lg">
                  <img
                    src={react}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  />
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  React Js
                </span>
              </div>

              {/* Node.js */}
              <div className="flex flex-col items-center ">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 bg-white rounded-full shadow-lg">
                  <img
                    src={talwindcss1}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  />
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  Tailwind CSS
                </span>
              </div>
            </div>
          </div>

          {/* SMS Gateway */}
          <div className="grid items-center w-full mx-auto md:grid md:grid-rows-1 md:grid-cols-2 xl:mb-4">
            <h2 className="flex items-start justify-start mb-4 text-2xl font-bold xl:items-center md:text-2xl latofont xl:w-full xl:flex 2xl:justify-center xl:pl-5 2xl:pl-[-1.5rem]">
              SMS Gateway:
            </h2>
            <div className="flex w-full space-x-3">
              {/* Socket.IO */}
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 bg-white rounded-full shadow-lg">
                  <img
                    src={sms}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  />
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  Twilio
                </span>
              </div>

              {/* Node.js */}
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 bg-white rounded-full shadow-lg">
                  <img
                    src={sms1}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  />
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  MSG91
                </span>
              </div>

              {/* MySQL */}
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-20 h-20 p-2 m-0 rounded-full ">
                  {/* <img
                    src={mysqlLogo}
                    alt="whoxa chat script"
                    className="w-12 h-12 rounded-full hover:w-10 hover:h-10"
                  /> */}
                </div>
                <span className="mt-4 text-lg font-semibold latofont">
                  {/* MySQL */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homesection4;
