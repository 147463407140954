import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";

// import LandingNavbar from "../LandingPage/LandingNavbar";

export default function PrivacyPolicy() {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const path = useLocation();

  // Function to fetch Privacy Policy data using POST
  async function fetchPrivacyPolicy() {
    try {
      const response = await fetch(
        "https://whoxachat.com/api/get-privacy-policy",
        {
          method: "POST", // Ensure you're using POST method
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}), // You may need to pass some request body, leave empty if not required
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json(); // Parsing JSON data from the response
      if (data && data.privacy_policy && data.privacy_policy.length > 0) {
        setPrivacyPolicyLink(data.privacy_policy[0].Link); // Assuming the link is stored here
      } else {
        console.error("No privacy policy link found in the response.");
      }
    } catch (error) {
      console.error("Error fetching privacy policy:", error);
    }
  }

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []); // This runs once when the component mounts

  const handleIframeLoad = () => {
    setIsIframeLoaded(true);
  };

  return (
    <>
      {/* {path.pathname === "/privacy-policy" && <LandingNavbar />} */}
      {!isIframeLoaded && (
        <div className="flex items-center justify-center h-screen text-black bg-white">
          <ClipLoader color="black" size={30} />
        </div>
      )}
      <div className=" absolute w-full top-28 text-center font-bold text-2xl">Privacy Policy</div>
      <div
        style={{ display: isIframeLoaded ? "block" : "none" }}
        className={`h-screen `}
      >
        <iframe
          className="pl-10 pt-36"
          srcDoc={privacyPolicyLink} // Embed HTML content directly using srcDoc
          width="100%"
          height="100%"
          sandbox="allow-same-origin allow-scripts allow-popups"
          onLoad={handleIframeLoad}
          title="Privacy Policy"
        ></iframe>
      </div>
    </>
  );
}
