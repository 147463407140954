import React from "react";
import admionpanelimgae from "../../assets/adminpanel.png";
function Homesection8() {
  return (
    <div className="text-center  bg-white max-w-[100%] px-4 md:mt-[3rem]">
      {/* Heading and Line Section */}
      <div className="relative inline-block w-auto mx-auto">
        <h2 className="text-[32px] md:text-[48px] lg:text-[40px] font-bold flex justify-center gap-4 md:gap-3 flex-wrap">
          <span className="text-[#FCC604] latofont">Admin </span>
          <span className="text-black latofont">Panel</span>
        </h2>

        {/* Line with dots under heading */}
        <div className="flex items-center justify-center mt-2">
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
          <div className="flex-grow h-1 bg-yellow-300 "></div>
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
        </div>
      </div>

      {/* Image Section */}
      <div className="flex justify-center items-center w-full max-w-[90%] sm:max-w-[70%] lg:max-w-[70%] mx-auto md:mt-[5rem] mt-[2rem] xl:mt-[1rem]">
        <img
          src={admionpanelimgae}
          alt="whoxa chat script"
          className="w-full h-auto"
        />
      </div>
    </div>
  );
}

export default Homesection8;
