import React from "react";
import bgimagesection6 from "../../assets/bgsection6.png";
import groupimag1 from "../../assets/6-1.png";
import groupimag2 from "../../assets/6-2.png";
import groupimag3 from "../../assets/6-3.png";
import groupimag4 from "../../assets/6-4.png";
import groupimag5 from "../../assets/6-5.png";
import groupimag6 from "../../assets/6-6.png";
import groupimag7 from "../../assets/6-7.png";
import arrow from "../../assets/arrow-right.jpg";
import { Link } from "react-router-dom";

function Homesection6() {
  return (
    <div
      className=" relative max-w-[85%] xl:max-w-[70%] md:max-w-[78%] lg:max-w-[70%] mx-auto h-[30vh] mt-[3rem]  xl:h-[35vh] flex justify-center items-center rounded-xl  hover:scale-105 transition-transform duration-500 ease-in-out mb-8"
      style={{
        backgroundImage: `url(${bgimagesection6})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Main content container with text and button */}
      <div className="absolute inset-0 z-20 flex flex-wrap items-center justify-between px-10 mx-auto max-w-[90%]">
        {/* Left Side Text */}
        <h4 className="text-xl font-bold text-[#000000] sm:text-2xl md:text-3xl  xl:text-[2rem] w-full lg:w-1/2 mb-4 lg:mb-0 text-center lg:text-left">
          Develop Your ChatApp Application Today!
        </h4>

        {/* Right Side Button */}
        <div className="flex items-center justify-center px-4 py-2 bg-white rounded-[20px] shadow-lg cursor-pointer  md:ml-[8rem] lg:w-auto  mt-[-3rem]  xl:mt-0  xl:py-3 xl:px-6 ml-4">
          <span className="px-3 mr-2 text-sm font-semibold md:text-base lg:text-lg xl:text-xl">
            <a href="https://primocys.com/contact" target="_blank">
              Start Now
            </a>
          </span>
          <img src={arrow} alt="whoxa chat script" className="w-10 h-6" />
        </div>
      </div>

      {/* Group of Images Positioned Absolutely */}
      <div className="relative flex flex-wrap items-center justify-center w-full h-full gap-4 max-w-[95%] z-10">
        {/* Individual Images with animation class */}
        {/* Top Left */}
        <img
          src={groupimag1}
          alt="whoxa chat script"
          className="absolute top-0 left-0 w-[50px] md:w-[80px] lg:w-[100px] animate-slide xl:w-[80px]"
        />
        {/* Top Right */}
        <img
          src={groupimag2}
          alt="whoxa chat script"
          className="absolute top-0 right-14 w-[50px] md:w-[80px] lg:w-[100px] animate-slide  xl:w-[80px]"
        />
        {/* Top Center */}
        <img
          src={groupimag3}
          alt="whoxa chat script"
          className="absolute top-14 left-1/2 transform -translate-x-1/2 w-[50px] md:w-[80px] lg:w-[100px] animate-slide  xl:w-[80px]"
        />
        {/* Bottom Left */}
        <img
          src={groupimag4}
          alt="whoxa chat script"
          className="absolute bottom-3 left-14 w-[50px] md:w-[80px] lg:w-[100px] animate-slide  xl:w-[80px]"
        />
        {/* Bottom Right */}
        <img
          src={groupimag5}
          alt="whoxa chat script"
          className="absolute bottom-10 right-7 w-[50px] md:w-[80px] lg:w-[100px] animate-slide  xl:w-[80px]"  
        />
        {/* Bottom Center */}
        <img
          src={groupimag6}
          alt="whoxa chat script"
          className="absolute bottom-0 right-[20%] transform -translate-x-1/2 w-[50px] md:w-[80px] lg:w-[100px] animate-slide  xl:w-[80px]"
        />
        {/* Left Side Center (Vertically Centered) */}
        <img
          src={groupimag7}
          alt="whoxa chat script"
          className="absolute left-[58%] top-[60%] transform -translate-y-1/2 w-[50px] md:w-[80px] lg:w-[100px] animate-slide  xl:w-[80px]"
        />
      </div>
    </div>
  );
}

export default Homesection6;
