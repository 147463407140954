import React from "react";
import section5image from "../../assets/Groupsection5.png";

function Homesection5() {
  return (
    <div className="text-center my-8 bg-white max-w-[100%] px-4 mt-[4rem]">
      {/* Heading and Line Section */}
      <div className="relative inline-block w-auto mx-auto mb-8">
        <h2 className="text-[32px] md:text-[48px] lg:text-[40px] font-bold flex justify-center gap-4 md:gap-3 flex-wrap">
          <span className="text-[#FCC604] latofont">Group </span>
          <span className="text-black latofont">Chat</span>
        </h2>

        {/* Line with dots under heading */}
        <div className="flex items-center justify-center mt-2">
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
          <div className="flex-grow h-1 bg-yellow-300"></div>
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
        </div>
      </div>

      {/* Image and Text Content Section */}
      <div className="max-w-[90%] md:max-w-[80%] lg:max-w-[70%] flex flex-col md:flex-row justify-between items-center mx-auto gap-8">
        {/* Image Section */}
        <div className="w-full md:w-[50%] flex justify-center md:justify-start mb-8 md:mb-0">
          <img
            src={section5image}
            alt="whoxa chat script"
            className="w-full h-auto"
          />
        </div>

        {/* Text Section */}
        <div className="w-full md:w-[50%] text-left">
          <h4 className="text-[28px] md:text-[36px] lg:text-[30px] font-bold flex justify-center md:justify-start gap-4 md:gap-3 flex-wrap mb-4">
            <span className="text-[#FCC604] latofont">Group </span>
            <span className="text-black latofont">Chat</span>
          </h4>
          <p className="mb-4 text-sm md:text-base lg:text-lg latofont">
            In this platform, numerous users have the capability to engage in
            simultaneous conversations, assign multiple administrators, and
            share various types of attachments, including documents, images,
            videos, contacts, recordings, and location information.
          </p>
        </div>
      </div>

      {/* Second Section with Image and Text (Reversed Layout) */}
      <div className="max-w-[90%] md:max-w-[80%] lg:max-w-[70%] flex flex-col md:flex-row-reverse justify-between items-center mx-auto gap-8 mt-8">
        {/* Image Section */}
        <div className="w-full md:w-[50%] flex justify-center md:justify-start mb-8 md:mb-0">
          <img
            src={section5image}
            alt="GroupChatImage"
            className="w-full h-auto"
          />
        </div>
        {/* Text Section */}
        <div className="w-full md:w-[50%] text-left">
          <h4 className="text-[28px] md:text-[36px] lg:text-[35px] font-bold flex justify-center md:justify-start gap-4 md:gap-3 flex-wrap mb-4">
            <span className="text-[#FCC604] latofont">Group</span>
            <span className="text-black latofont">Detail</span>
          </h4>
          <p className="mb-4 text-sm md:text-base lg:text-lg latofont">
            Within the group details, users are able to view the total number of
            participants who have been added to the group, as well as any media,
            links, and documents that have been shared. Group participants can
            also access messages that have been marked as starred. Furthermore,
            users have the option to block, report, or delete their membership
            in the group.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Homesection5;
