import React from "react";
import f1icon from "../../assets/avatar.png";
import f2 from "../../assets/call.png";
import f3 from "../../assets/f1.png";
import f5 from "../../assets/groupchat.png";
import f6 from "../../assets/f6.png";
import f7 from "../../assets/f7.png";
import f8 from "../../assets/f8.png";
import f9 from "../../assets/f9.png";
import f10 from "../../assets/f10.png";
import f12 from "../../assets/f12.png";
import f15 from "../../assets/f15.png";
import share from "../../assets/Group 1410115758.png";
import chat1 from "../../assets/chat11.png";
import editchart from "../../assets/editprofile.png";

function Homesection2() {
  return (
    <div className="text-center  bg-white max-w-[100%] px-4 mt-[2rem] xl:mt-[3rem] ">
      {/* Heading and Line Section */}
      <div className="relative inline-block w-auto mx-auto">
        <h2 className="text-[32px] md:text-[48px] lg:text-[40px] font-bold flex justify-center gap-4 md:gap-3 flex-wrap">
          <span className="text-[#FCC604] latofont">Chat app</span>
          <span className="text-black latofont">Features</span>
        </h2>

        {/* Line with dots under heading */}
        <div className="flex items-center justify-center mt-2">
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
          <div className="flex-grow h-1 bg-yellow-300 "></div>
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
        </div>
      </div>

      {/* Image Section */}
      <div className="grid justify-center items-center   place-items-center py-4  w-full max-w-[90%] sm:max-w-[70%] md:max-w-[90%] lg:max-w-[90%] 2xl:max-w-[60%] xl:max-w-[70%] mx-auto  mt-[2rem] xl:mt-[4rem]  xl:grid-cols-3 2xl:grid-cols-4 gap-7 gap-y-12 md:grid-cols-2">
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img
              src={f1icon}
              alt="whoxa chat script"
            />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont line-clamp-1">
              Avtars
            </h3>
            <div className="w-[80%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                Instead of uploading image you can choose the avtar of your
                choice
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f2} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Phone Authentication
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                Verify your number before you access the whoxachat web
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f3} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Single Chat
            </h3>
            <div className="w-[80%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                User can chat with single user at a time
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f5} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Group Chat
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                Here multiple users can chat simultaneously and can assign
                multiple admin
              </p>
            </div>
          </div>
        </div>

        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f6} alt="whoxa chat script" />
          </div>

          <div className="mt-4 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Delete
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-3">
                Delete messages can be multiple, it can be delete for me or
                delete for everyone
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f7} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Add Participants
            </h3>
            <div className="w-[80%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm">
                Add number of Participants to new group chat
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f8} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Clear Chat
            </h3>
            <div className="w-[80%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm">
                Clear the chat messages of the user or the group
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f9} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Search Chat
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                Search chat feature for the easy accessible to the number of
                messages
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f10} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="mt-1 text-xl font-semibold text-black latofont">
              Contact List
            </h3>
            <div className="w-[100%] mx-auto mt-1 ">
              <p className="text-[#666666] font-normal latofont text-sm ">
                Contact list can be all users for the whoxa chat or the phone
                contact that can be set through admin panel
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={editchart} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Edit Profile
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                Edit profile has all the user information and can edit the data
                too
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f12} alt="whoxa chat script" />
          </div>

          <div className="mt-4 text-center ">
            <h3 className="text-xl font-semibold text-black latofont">
              Block Contacts
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm">
                The users which has been blocked by the user can be shown in
                block contacts list
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f5} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">Reply</h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                You can reply to the particular message by clicking on reply
                icon
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={share} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Forward Messages
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                User can forward multiple messages
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f12} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Report user
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm">
                You can report user for the list of reasons, that can be seen by
                admin
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={chat1} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Archived Chat
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                The chats which user don't want to show in chat list can be
                archived
              </p>
            </div>
          </div>
        </div>
        <div
          className="h-[129px] w-[300px]  md:w-[262px]  lg:w-[310px] xl:w-[262px] border-2 border-yellow-400 flex flex-col items-center justify-center relative shadow-lg rounded-lg p-4"
          style={{
            boxShadow: "10px 1px 7.8px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          {/* Icon at the top center */}
          <div className="h-[3.30rem] w-[3.30rem] absolute top-[-1.5rem]">
            <img src={f15} alt="whoxa chat script" />
          </div>

          <div className="mt-2 text-center">
            <h3 className="text-xl font-semibold text-black latofont">
              Dark/Light Mode
            </h3>
            <div className="w-[90%] mx-auto ">
              <p className="text-[#666666] font-normal latofont text-sm line-clamp-2">
                User can switch to light or dark mode as per their convenience
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homesection2;
