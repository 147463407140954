import React from "react";
import HomesectionImage from "../../assets/homesection3.png";

function Homesection3() {
  return (
    <div className="text-center my-8  max-w-[100%] px-4 ">
      {/* Heading and Line Section */}
      <div className="relative inline-block w-auto mx-auto pt-[1rem] ">
        <h2 className="text-[32px] md:text-[48px] lg:text-[40px] font-bold flex justify-center gap-4 md:gap-3 flex-wrap">
          <span className="text-[#FCC604] latofont">Chat app</span>
          <span className="text-black latofont">settings</span>
        </h2>

        {/* Line with dots under heading */}
        <div className="flex items-center justify-center mt-2">
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
          <div className="flex-grow h-1 bg-yellow-300 "></div>
          <div className="w-3 h-3 bg-white border-2 border-yellow-300 rounded-full"></div>
        </div>
      </div>
      <div className="flex justify-center items-center w-full max-w-[90%] sm:max-w-[70%] xl:max-w-[70%] 2xl:max-w-[60%] md:max-w-[80%] mx-auto mt-8 md:pt-[3rem] xl:pt-[2rem] ">
        <img
          src={HomesectionImage}
          alt="whoxa chat script"
          className="w-full h-auto"
        />
      </div>
    </div>
  );
}

export default Homesection3;
