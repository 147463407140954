import React from "react";
import HomeBgImage from "../../assets/Home.png";
import Laptop from "../../assets/laptop.png";
import Recodingimage from "../../assets/recording.png";
import docImage from "../../assets/doc.png";
import videoImage from "../../assets/video.png";
import messageImage from "../../assets/message.png";
import Imagetopbottome from "../../assets/imagerightbottome.png";
import contactleftbottome from "../../assets/contactleftbottome.png";
import toplocationimage from "../../assets/location.png";
import btnImage from "../../assets/btnimage.png";
function Homesection1() {
  return (
    <main
      className="min-w-full  bg-white mt-[4rem] h-auto"
      style={{
        backgroundImage: `url(${HomeBgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section className="xl:max-w-[60%]  mx-auto pt-[3rem]  overflow-hidden min-h-fit">
        <div className="mx-auto text-center ">
          <h1 className="font-[500] text-[#000000]  xl:text-[40px]  Poppins-font lg:text-6xl md:text-5xl sm:text-5xl smallscreen:text-2xl">
            Explore new connections, and Whoxa Chat Script features
          </h1>
          <div className="xl:max-w-[75%] mx-auto pt-[2rem] lg:max-w-[75%] md:max-w-[80%] sm:max-w-[90%] smallscreen:max-w-[95%]">
            <p className="font-[400] text-[#000000] xl:text-xl Poppins-font lg:text-2xl md:text-xl sm:text-lg tracking-wide">
              Explore Whoxa Chat Script for powerful features and to stay
              connected anytime and anywhere
            </p>
          </div>
        </div>
        <div className="m-0 mt-[-6.5rem] ">
          <div className="relative xl:max-w-[100%] mx-auto pt-[8rem] xl:pt-[6.8rem]">
            {/* The Image */}
            <div className="items-center">
              <img src={Laptop} alt="whoxa chat script" />
            </div>

            {/* Buttons positioned around the image */}

            {/* Top Button */}
            <div className="element">
              <div className="flex flex-col items-center justify-center md:ml-[-4.8rem] ml-[3rem]">
                <img
                  src={docImage}
                  alt="whoxa chat script"
                  className="2xl:w-[5rem] 2xl:h-[5rem] smallscreen:w-[2rem] smallscreen:h-[2rem] sm:w-[3.7rem] md:w-[4rem] md:h-[4rem] lg:w-[4rem] xl:w-[4rem] sm:h-[3.7rem] lg:h-[4rem] xl:h-[4rem] smallscreen:top-[7.4rem] sm:top-[9rem] md:top-[9rem] lg:top-[9.8rem] xl:top-[7.5rem] absolute 2xl:top-[8.5rem] z-10 smallscreen:ml-[-2rem] sm:ml-0 bounce-animation" // Maintain space between image and text
                />
                <button className="absolute 2xl:top-[12.5rem] smallscreen:top-[9rem] smallscreen:text-sm sm:top-[12rem] lg:top-[12.8rem] md:top-[12rem] sm:left-1/2 smallscreen:left-[41%] md:left-[37%] xl:top-[11rem] lg:left-[40%] xl:left-[37%] 2xl:left-[40%] transform -translate-x-1/2 bg-white text-black md:px-5 sm:px-4 lg:px-6 lg:py-4 sm:py-3 md:py-3 lg:text-lg sm:text-sm xl:px-6 smallscreen:py-2 smallscreen:px-2 md:text-lg xl:py-4 xl:text-lg 2xl:px-7 2xl:py-5 rounded-md shadow btn flex flex-col font-[400] items-center BeVietnam-font text-lg bounce-animation">
                  Document
                </button>
              </div>
            </div>

            {/* Top-Left Button */}
            <div className="flex flex-col items-center justify-center ">
              <img
                src={Recodingimage}
                alt="whoxa chat script"
                className=" bounce-animation 2xl:w-[5rem] lg:w-[4.2rem] sm:h-[3.2rem] smallscreen:w-[2.1rem] smallscreen:h-[2.1rem] sm:w-[3.2rem] md:h-[3.5rem] md:w-[3.5rem] lg:h-[4.2rem] xl:w-[4.5rem] xl:h-[4.5rem] 2xl:h-[5rem] absolute sm:top-[10rem] smallscreen:top-[9rem] md:top-[12rem] lg:top-[13rem] xl:top-[11rem] 2xl:top-[14rem] lg:left-[3.8rem] sm:left-[2rem] smallscreen:left-[1.8rem] md:left-[2.5rem] xl:left-[-0rem] 2xl:left-[-0.5rem]  z-10"
              />
              <button className="bounce-animation  absolute sm:left-[4rem] smallscreen:left-[0.7rem]  2xl:left-[-2.3rem] md:text-lg md:left-[0.5rem] lg:left-[2rem] xl:left-[-1.8rem] smallscreen:top-[10.5rem] sm:top-[12.5rem] md:top-[14.5rem] xl:top-[14rem] lg:top-[16.3rem] 2xl:top-[18rem] btn transform -translate-x-1/2 bg-white text-black lg:px-6 sm:px-4 sm:py-3 md:py-3 md:px-4 smallscreen:px-2 smallscreen:py-2 smallscreen:text-xs lg:py-4 xl:px-7 xl:py-5 2xl:px-7 2xl:py-5 rounded-md shadow btn flex flex-col font-[400] items-center BeVietnam-font text-lg">
                Recording
              </button>
            </div>

            {/* Top-Right Button */}
            <div className="flex flex-col items-center justify-center ">
              <img
                src={toplocationimage}
                alt="whoxa chat script"
                className=" bounce-animation  2xl:w-[5rem] xl:h-[4.2rem]  xl:w-[4.2rem] xl:top-[10.5rem] lg:w-[4.5rem] lg:top-[15.5rem] md:top-[12.9rem] md:h-[4rem] md:w-[4rem]  sm:h-[3.4rem] sm:w-[3.4rem] sm:top-[10.5rem] sm:right-[6rem] smallscreen:h-[2.1rem] smallscreen:w-[2.1rem] smallscreen:right-[1.5rem] smallscreen:top-[9.4rem] lg:h-[4.5rem] 2xl:h-[5rem] absolute 2xl:top-[14rem] 2xl:right-[6.8rem] xl:right-[4rem] lg:right-[10.5rem] md:right-[6rem] z-10"
              />

              <button className="bounce-animation  absolute xl:top-[14rem] lg:top-[19rem] md:top-[16rem] sm:top-[13rem] smallscreen:top-[11rem] 2xl:top-[18rem] xl:right-[1.8rem] lg:right-[9rem] md:right-[4.5rem] sm:right-[1rem] smallscreen:right-[0.75rem] smallscreen:text-xs sm:text-lg 2xl:right-[5.2rem] btn transform -translate-x-1/2 bg-white text-black xl:px-7 xl:py-4 md:px-5 md:py-4 sm:px-4 sm:py-3 smallscreen:px-1  smallscreen:py-2 lg:px-5 lg:py-4 2xl:px-7 2xl:py-5 rounded-md shadow btn flex flex-col font-[400] items-center BeVietnam-font text-lg">
                Location
              </button>
            </div>

            {/* Left-Center Button */}
            <div className="flex flex-col items-center justify-center ">
              <img
                src={videoImage}
                alt="whoxa chat script"
                className="2xl:w-[5rem] bounce-animation xl:w-[4.6rem] lg:w-[4.5rem] md:w-[4rem] sm:w-[3.5rem] smallscreen:w-[2.1rem] 2xl:h-[5rem] xl:h-[4.6rem] lg:h-[4.rem] md:h-[4rem] sm:h-[3.5rem] smallscreen:h-[2.1rem] absolute 2xl:top-[50%] xl:top-[47%] lg:top-[54%] md:top-[53%] sm:top-[52%] smallscreen:top-[59%]  2xl:left-[1.5rem] xl:left-[-0rem] lg:left-[2rem] md:left-[2rem] sm:left-[2rem] smallscreen:left-[0.8rem]  z-10"
              />
              <button className="absolute bounce-animation 2xl:top-[56.5%] xl:top-[55%] lg:top-[60%] md:top-[60%] sm:top-[63%] smallscreen:top-[65%]  2xl:left-0 xl:left-[-2rem] lg:left-3 md:left-4 sm:left-4 smallscreen:left-[.4rem] transform -translate-y-1/2 btn  bg-white text-black 2xl:px-10 xl:px-12 lg:px-9 md:px-6 sm:px-5 smallscreen:px-2 2xl:py-5 xl:py-5 lg:py-4 md:py-3 sm:py-2 smallscreen:py-2 sm:text-lg smallscreen:text-xs rounded-md shadow btn flex flex-col font-[400] items-center BeVietnam-font text-lg">
                Video
              </button>
            </div>

            {/* Right-Center Button */}
            <div className="flex flex-col items-center justify-center ">
              <img
                src={messageImage}
                alt="whoxa chat script"
                className="bounce-animation   2xl:w-[4rem] xl:w-[4rem] lg:w-[3.5rem] md:w-[3.5rem] sm:w-[3rem] smallscreen:w-[2.1rem] 2xl:h-[4rem] xl:h-[4rem] lg:h-[3.5rem] md:h-[3.5rem] sm:h-[3.5rem] smallscreen:h-[2.1rem] absolute 2xl:top-[49%] xl:top-[47%] lg:top-[48.5%] md:top-[53%] sm:top-[54%] smallscreen:top-[54%]  2xl:right-[5.5rem] xl:right-[3rem] lg:right-[4.5rem] md:right-[4rem] sm:right-[3rem] smallscreen:right-[1.7rem]  z-10"
              />
              <button
                className="bounce-animation  absolute 2xl:top-[54%] xl:top-[55%] lg:top-[54%] md:top-[58%] sm:top-[60%] smallscreen:top-[60%]  2xl:right-[3.5rem]  xl:right-[0.5rem] lg:right-[2rem] md:right-[2rem] sm:right-[-2rem] smallscreen:right-[1rem]  btn transform -translate-x-1/2  text-black 2xl:px-7 xl:px-7 lg:px-6 md:px-5 sm:px-3 smallscreen:px-1  2xl:py-5 xl:py-3 lg:py-3 md:py-3 sm:py-2 smallscreen:py-2 rounded-lg shadow btn flex flex-col font-[400] items-center BeVietnam-font smallscreen:text-xs sm:text-lg text-lg"
                style={{
                  background:
                    "linear-gradient(180deg, #FFEDAB 0%, #FCC604 100%)",
                }}
              >
                Message
              </button>
            </div>

            {/* Right-Bottom Button */}

            <div className="flex flex-col items-center justify-center bounce-animation">
              <img
                src={Imagetopbottome}
                alt="whoxa chat script"
                className="2xl:w-[4rem] xl:w-[4rem] lg:w-[3.5rem] md:[3.5rem] sm:w-[3rem] smallscreen:w-[2.1rem] 2xl:h-[4rem] xl:h-[4rem] lg:h-[3.5rem] md:h-[3.5rem] sm:h-[3rem] smallscreen:h-[2.1rem] absolute 2xl:bottom-[15rem] xl:bottom-[10rem] lg:bottom-[14rem] md:bottom-[9.7rem] sm:bottom-[7.5rem] smallscreen:bottom-[4.5rem] 2xl:right-[5.6rem] xl:right-[1.8rem] lg:right-[5rem] md:right-[4rem] sm:right-[4rem] smallscreen:right-[1.2rem]  z-10"
              />
              <button
                className="absolute 2xl:bottom-[12rem] xl:bottom-[6.5rem] lg:bottom-[11rem] md:bottom-[7rem] sm:bottom-[5rem] smallscreen:bottom-[3rem]  2xl:right-[-2rem] xl:right-[-6rem] lg:right-[-2rem] md:right-[-2rem] sm:right-[-1rem] smallscreen:right-[-1rem] btn transform -translate-x-1/2  text-black 2xl:px-[3rem] xl:px-[3rem] lg:px-[2.5rem] md:px-[2rem] sm:px-[1.5rem] smallscreen:px-2 smallscreen:text-xs sm:text-lg 2xl:py-5 xl:py-5 lg:py-4 md:py-3 sm:py-3 smallscreen:py-2 rounded-md shadow btn flex flex-col font-[400] items-center BeVietnam-font text-lg"
                style={{
                  background:
                    "linear-gradient(180deg, #FFEDAB 0%, #FCC604 100%)",
                }}
              >
                Image
              </button>
            </div>

            {/* Left-Bottom Button */}

            <div className="flex flex-col items-center justify-center bounce-animation">
              <img
                src={contactleftbottome}
                alt="whoxa chat script"
                className="2xl:w-[4rem] xl:w-[4rem] lg:w-[3.5rem] md:w-[3rem] sm:w-[2.8rem] smallscreen:w-[2.1rem] 2xl:h-[4rem] xl:h-[4rem] lg:h-[3.5rem] md:h-[3rem] sm:h-[2.8rem] smallscreen:h-[2.1rem] absolute 2xl:bottom-[15rem] xl:bottom-[10rem] lg:bottom-[14rem] md:bottom-[10rem] sm:bottom-[15rem] smallscreen:bottom-[4.5rem] 2xl:left-[2rem]  xl:left-[0.5rem] lg:left-[2.3rem] md:left-[2.1rem] sm:left-[1.5rem] smallscreen:left-[1.2rem] z-10"
              />
              <button
                className="absolute 2xl:bottom-[12rem] xl:bottom-[6.5rem] lg:bottom-[11rem] md:bottom-[7rem] sm:bottom-[10rem] smallscreen:bottom-[2.8rem] 2xl:left-[4rem] xl:left-[2rem] lg:left-[4.3rem] md:left-[4rem] sm:left-[3rem] smallscreen:left-[2.4rem] btn  transform -translate-x-1/2  text-black 2xl:px-[2rem]  xl:px-[2.5rem] lg:px-[1.2rem] md:px-[1.2rem] sm:px-[1.4rem] smallscreen:px-[0.4rem] sm:text-lg smallscreen:text-xs 2xl:py-5 xl:py-5 lg:py-4 md:py-3 sm:py-3 smallscreen:py-2 rounded-md shadow btn flex flex-col font-[400] items-center BeVietnam-font text-lg"
                style={{
                  background:
                    "linear-gradient(180deg, #FFEDAB 0%, #FCC604 100%)",
                }}
              >
                Contact
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center ">
          <div className="grid  md:grid-rows-1 md:grid-cols-2 gap-y-[2rem] md:space-x-4">
            {/* First button: Explore Frontend */}
            <button className="px-6 py-2 text-black transition border border-gray-400 rounded-lg hover:bg-gray-100">
              <a href="https://web.whoxachat.com/login" target="_blank">
                Explore Frontend
              </a>
            </button>

            {/* Second button: Explore Admin Panel */}
            <button className="px-6 py-2 text-white transition bg-black rounded-lg hover:bg-gray-800">
              <a href="https://admin.whoxachat.com/" target="_blank">
                Explore Admin Panel
              </a>
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Homesection1;
