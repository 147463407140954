import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Layout from "./Layout/Layout";
import AllSections from "./Pages/Home/AllSections";

import TermsAndCondition from "./Pages/Termcondtion/Termcondition.jsx";
import PrivacyPolicy from "./Pages/Privacypolicy/Privacypolicy.jsx";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="" element={<AllSections />} />
      <Route path="Features" element={<AllSections />} />
      <Route path="Settings" element={<AllSections />} />
      <Route path="Technology" element={<AllSections />} />
      <Route path="GroupChat" element={<AllSections />} />
      <Route path="AdminPanel" element={<AllSections />} />
      <Route path="contact" element={<AllSections />} />
      <Route path="terms-condition" element={<TermsAndCondition />} />{" "}
      <Route path="privacypolicy" element={<PrivacyPolicy/>} />{" "}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
