// src/Pages/Home/AllSections.js

import React from "react";
import Homesection2 from "./Homesection2";
import Homesection3 from "./Homesection3";
import Homesection4 from "./Homesection4";
import Homesection5 from "./Homesection5";
import Homesection6 from "./Homesection6";
import Homesection8 from "./Homesection8";
import Homesection1 from "./Homesection1";
import whatshop from "../../assets/whatshop.png";

function AllSections() {
  return (
    <>
      <Homesection1 />
      <Homesection2 />
      <Homesection3 />
      <Homesection4 />
      <Homesection5 />
      <Homesection6 />
      <Homesection8 />

      <div className="fixed flex cursor-pointer bottom-3 right-8 h-[3rem] w-[3rem]">
        <a
          href="https://api.whatsapp.com/send/?phone=919601814016&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Chat with us on WhatsApp"
        >
          <img
            src={whatshop} // Assuming `whatshop` is the image source path
            alt="Whatshop link"
            className="object-cover w-full h-full animate-scaleUpDown" // Use custom animation class here
          />
        </a>
      </div>
    </>
  );
}

export default AllSections;
