import React from "react";

function Homesectionfotter() {
  return (
    <>
      <div className="w-100% h-full py-5 flex justify-center items-center bg-[#000000] ">
        <div className="w-[80%] mx-auto flex justify-center items-center">
          <p className="font-[400] text-[#FFFFFF] white">
            Copyright © 2000-2024{" "}
            <a
              href="https://primocys.com"
              target="_blank"
              className="text-blue-500 underline cursor-pointer latofont"
            >
              Primocys .
            </a>{" "}
             All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default Homesectionfotter;
