import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import PrivacyPolicy from "../Privacypolicy/Privacypolicy";

export default function TermsAndCondition() {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [termsAndConditionLink, setTermsAndConditionLink] = useState("");
  const [loadingError, setLoadingError] = useState(false);
  let path = useLocation();

  // Function to fetch Terms and Conditions link from the API using POST
  async function fetchTermsAndCondition() {
    try {
      const response = await fetch("https://whoxachat.com/api/get-tncs", {
        method: "POST", // Change to POST method
        headers: {
          "Content-Type": "application/json", // Sending JSON data
        },
        body: JSON.stringify({
          /* Add any required parameters here if needed */
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Terms and Conditions");
      }

      const data = await response.json();
      // Set the HTML content of the Link directly
      setTermsAndConditionLink(data.TandCs[0]?.Link || "");
    } catch (error) {
      setLoadingError(true);
      console.error("Error fetching terms and conditions:", error);
    }
  }

  useEffect(() => {
    fetchTermsAndCondition();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const handleIframeLoad = () => {
    setIsIframeLoaded(true);
  };

  // Loading and error handling UI
  if (loadingError) {
    return (
      <div className="flex items-center justify-center h-screen text-black bg-white">
        <p>Error loading Terms and Conditions. Please try again later.</p>
      </div>
    );
  }

  if (!termsAndConditionLink) {
    return (
      <div className="flex items-center justify-center h-screen text-black bg-white">
        <ClipLoader color="black" size={30} />
      </div>
    );
  }

  return (
    <>
      {/* {path.pathname === "/privacy-policy" && <LandingNavbar />} */}
      <div className=" absolute w-full top-28 text-center font-bold text-2xl">
        Terms and Conditions
      </div>
      <div
        style={{ display: isIframeLoaded ? "block" : "none" }}
        className="font-LimerickRegular scrolling-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white scrollbar-thumb-rounded-full mx-auto flex h-screen min-h-[50rem] w-screen snap-x snap-mandatory flex-col items-center justify-start overflow-auto overflow-y-auto overflow-x-hidden bg-white pt-20 lg:px-0 xl:my-auto"
      >
        <iframe
          className="pl-10 pt-20"
          srcDoc={termsAndConditionLink} // Embed HTML content using srcDoc
          width="100%"
          height="100%"
          sandbox="allow-same-origin allow-scripts allow-popups"
          onLoad={handleIframeLoad}
          title="Terms and Conditions"
        />
      </div>
    </>
  );
}
