import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Pages/Navbar/Navbar";
import Homesection7 from "../Pages/Home/Homesection7";

function Layout() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);

    // Define custom scroll positions for different routes
    const scrollPositions = {
      "/": { x: 0, y: 0 },
      "/AdminPanel": { x: 0, y: 5400 },
      "/Features": { x: 0, y: 1200 },
      "/Pricing": { x: 0, y: 400 },
      "/Settings": { x: 0, y: 2100 },
      "/Technology": { x: 0, y: 3200 },
      "/GroupChat": { x: 0, y: 3900 },
      "/Help": { x: 0, y: 900 },
    };

    // Get the custom scroll position based on the current pathname
    const scrollTo = scrollPositions[location.pathname];

    // If a custom scroll position exists for the route, scroll to it
    if (scrollTo) {
      window.scrollTo(scrollTo.x, scrollTo.y);
    }
  }, [location]);
console.log(location.pathname,'location.pathname');

  return (
    <>
      <Navbar />
      <Outlet />
      {location.pathname == "/privacypolicy" ||
      location.pathname == "/terms-condition" ? (
        ""
      ) : (
        <Homesection7 />
      )}
    </>
  );
}

export default Layout;
